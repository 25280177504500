import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import {
  GET_CAMPAIGN_TEMPLATE_REPORTS,
  GET_TOP_CAMPAIGN_TEMPLATES,
} from "services/url.service";
import { isTopCampaignTemplatesEnabled } from "constants";

export const campaignTemplateMutations = {};

export const campaignTemplateQueries = {
  getCampaignTemplateReports: {
    getUrl: ({ search, filters, companyId }) => {
      return GET_CAMPAIGN_TEMPLATE_REPORTS(companyId, search, filters);
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  getTopCampaignTemplates: {
    getUrl: ({ filters, limit, companyId }) => {
      return isTopCampaignTemplatesEnabled
        ? GET_TOP_CAMPAIGN_TEMPLATES(companyId, filters, limit)
        : { status: true, entity: [] };
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
};
